import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { getMetaCharacter } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  TextAlignCenterIcon,
  TextAlignJustifyIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
} from '@tyro/icons';
import { useState } from 'react';
import { ToggleButton } from '../toolbar-buttons/toggle-button';

interface FontStylingProps {
  editor: Editor | null;
}

export default function TextAlignmentToggle({ editor }: FontStylingProps) {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const metaCharacter = getMetaCharacter();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ('key' in event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <ToggleButton
        title="Alignment"
        onClick={(event) => handleMenuOpen(event)}
        value="insertColumns"
      >
        <TextAlignLeftIcon />
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,

            '& .MuiMenuItem-root': {
              paddingX: 1,
              margin: 0,
            },
          },
        }}
      >
        <Typography paddingX={1} pt={1} fontSize="14px">
          {t('common:addColumns')}
        </Typography>
        <Stack flexDirection="row">
          {[
            {
              id: 'alignLeft',
              title: t('common:tooltipTitles.alignLeft', {
                shortcut: `${metaCharacter} + Shift + L`,
              }),
              icon: <TextAlignLeftIcon />,
              action: () => {
                editor?.chain().setTextAlign('left').run();
                setAnchorEl(null);
              },
            },
            {
              id: 'alignRight',
              title: t('common:tooltipTitles.alignRight', {
                shortcut: `${metaCharacter} + Shift + R`,
              }),
              icon: <TextAlignRightIcon />,
              action: () => {
                editor?.chain().setTextAlign('right').run();
                setAnchorEl(null);
              },
            },
            {
              id: 'alignCenter',
              title: t('common:tooltipTitles.alignCenter', {
                shortcut: `${metaCharacter} + Shift + E`,
              }),
              icon: <TextAlignCenterIcon />,
              action: () => {
                editor?.chain().setTextAlign('center').run();
                setAnchorEl(null);
              },
            },
            {
              id: 'alignJustify',
              title: t('common:tooltipTitles.alignJustify', {
                shortcut: `${metaCharacter} + Shift + J`,
              }),
              icon: <TextAlignJustifyIcon />,
              action: () => {
                editor?.chain().setTextAlign('justify').run();
                setAnchorEl(null);
              },
            },
          ].map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              sx={{ paddingX: 1 }}
              onClick={menuItem.action}
            >
              {menuItem.icon}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}
