/**
 * NOTE: this code was taken from:
 * https://github.com/bae-sh/tiptap-extension-resize-image
 */
import Image from '@tiptap/extension-image';
import type { TFunction } from '@tyro/i18n';

import { Card, Divider, IconButton } from '@mui/material';
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  TrashIcon,
} from '@tyro/icons';
import { getReactAsHtml } from './get-react-as-html';

export const ImageResize = (t: TFunction<'common'[]>) =>
  Image.extend({
    addAttributes() {
      return {
        src: {
          default: null,
        },
        alt: {
          default: null,
        },
        style: {
          default:
            'max-width: 100%; height: auto; cursor: pointer; vertical-align: bottom; display: block;',
          parseHTML: (element) => {
            const width = element.getAttribute('width');
            return width
              ? `width: ${width}px; height: auto; cursor: pointer;`
              : `${element.style.cssText}`;
          },
        },
        title: {
          default: null,
        },
        loading: {
          default: null,
        },
        srcset: {
          default: null,
        },
        sizes: {
          default: null,
        },
        crossorigin: {
          default: null,
        },
        usemap: {
          default: null,
        },
        ismap: {
          default: null,
        },
        width: {
          default: null,
        },
        height: {
          default: null,
        },
        referrerpolicy: {
          default: null,
        },
        longdesc: {
          default: null,
        },
        decoding: {
          default: null,
        },
        class: {
          default: null,
        },
        id: {
          default: null,
        },
        name: {
          default: null,
        },
        draggable: {
          default: true,
        },
        tabindex: {
          default: null,
        },
        'aria-label': {
          default: null,
        },
        'aria-labelledby': {
          default: null,
        },
        'aria-describedby': {
          default: null,
        },
      };
    },
    addNodeView() {
      return ({ node, editor, getPos }) => {
        const {
          view,
          options: { editable },
        } = editor;
        const { style } = node.attrs;

        const $wrapper = document.createElement('div');
        const $container = document.createElement('div');
        const $img = document.createElement('img');

        const dispatchNodeView = () => {
          if (typeof getPos === 'function') {
            const newAttrs = Object.assign(Object.assign({}, node.attrs), {
              style: `${$img.style.cssText}`,
            });
            view.dispatch(
              view.state.tr.setNodeMarkup(getPos(), null, newAttrs),
            );
          }
        };

        const paintPositionContoller = () => {
          const $postionController = document.createElement('div');

          $postionController.innerHTML = getReactAsHtml(
            <Card
              variant="elevation"
              sx={{
                position: 'absolute',
                top: '0%',
                left: '50%',
                cursor: 'pointer',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 1.5,
                p: 0.5,
                zIndex: 1,
              }}
            >
              <IconButton
                id="left-button"
                size="small"
                aria-label={t('common:tooltipTitles.alignImageLeft')}
              >
                <AlignLeftIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
              <IconButton
                id="center-button"
                size="small"
                aria-label={t('common:tooltipTitles.alignImageCenter')}
              >
                <AlignCenterIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
              <IconButton
                id="right-button"
                size="small"
                aria-label={t('common:tooltipTitles.alignImageRight')}
              >
                <AlignRightIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              <IconButton
                id="delete-button"
                size="small"
                aria-label={t('common:actions.delete')}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rose.lighter',
                  },
                }}
              >
                <TrashIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
            </Card>,
          );

          const leftButton = $postionController.querySelector('#left-button');
          const centerButton =
            $postionController.querySelector('#center-button');
          const rightButton = $postionController.querySelector('#right-button');
          const deleteButton =
            $postionController.querySelector('#delete-button');

          const imageStyle = $img.style.cssText;

          if (leftButton) {
            leftButton.addEventListener('click', () => {
              $img.setAttribute('style', `${imageStyle} margin: 0 auto 0 0;`);
              dispatchNodeView();
            });
          }

          if (centerButton) {
            centerButton.addEventListener('click', () => {
              $img.setAttribute('style', `${imageStyle} margin: 0 auto;`);
              dispatchNodeView();
            });
          }

          if (rightButton) {
            rightButton.addEventListener('click', () => {
              $img.setAttribute('style', `${imageStyle} margin: 0 0 0 auto;`);
              dispatchNodeView();
            });
          }

          if (deleteButton) {
            deleteButton.addEventListener('click', () => {
              if (typeof getPos === 'function') {
                view.dispatch(
                  view.state.tr.delete(getPos(), getPos() + node.nodeSize),
                );
              }
            });
          }

          $container.appendChild($postionController);
        };

        $wrapper.setAttribute('style', 'display: flex;');
        $wrapper.appendChild($container);
        $container.setAttribute('style', `${style}`);
        $container.appendChild($img);

        // biome-ignore lint/complexity/noForEach: <explanation>
        Object.entries(node.attrs).forEach(([key, value]) => {
          if (value === undefined || value === null) return;
          $img.setAttribute(key, value);
        });

        if (!editable) return { dom: $img };

        const dotsPosition = [
          'top: -4px; left: -4px; cursor: nwse-resize;',
          'top: -4px; right: -4px; cursor: nesw-resize;',
          'bottom: -4px; left: -4px; cursor: nesw-resize;',
          'bottom: -4px; right: -4px; cursor: nwse-resize;',
        ];

        let isResizing = false;
        let startX = 0;
        let startWidth = 0;

        $container.addEventListener('click', () => {
          if ($container.childElementCount > 3) {
            for (let i = 0; i < 5; i++) {
              $container.removeChild($container.lastChild!);
            }
          }
          paintPositionContoller();
          $container.setAttribute(
            'style',
            `position: relative; border: 1px dashed #6C6C6C; ${style} cursor: pointer;`,
          );

          Array.from({ length: 4 }, (_, index) => {
            const $dot = document.createElement('div');
            $dot.setAttribute(
              'style',
              `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${dotsPosition[index]}`,
            );
            $dot.addEventListener('mousedown', (e) => {
              e.preventDefault();
              isResizing = true;
              startX = e.clientX;
              startWidth = $container.offsetWidth;
              const onMouseMove = (e: MouseEvent) => {
                if (!isResizing) return;
                const deltaX =
                  index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
                const newWidth = startWidth + deltaX;
                $container.style.width = `${newWidth}px`;
                $img.style.width = `${newWidth}px`;
              };
              const onMouseUp = () => {
                if (isResizing) {
                  isResizing = false;
                }
                dispatchNodeView();
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
              };
              document.addEventListener('mousemove', onMouseMove);
              document.addEventListener('mouseup', onMouseUp);
            });
            $container.appendChild($dot);
          });
        });
        document.addEventListener('click', (e) => {
          const $target = e.target;
          const isClickInside = $container.contains($target as Node);
          if (!isClickInside) {
            const containerStyle = $container.getAttribute('style');
            const newStyle =
              containerStyle === null || containerStyle === void 0
                ? void 0
                : containerStyle.replace('border: 1px dashed #6C6C6C;', '');
            if (newStyle) $container.setAttribute('style', newStyle);
            if ($container.childElementCount > 3) {
              for (let i = 0; i < 5; i++) {
                $container.removeChild($container.lastChild!);
              }
            }
          }
        });
        return {
          dom: $wrapper,
        };
      };
    },
  });
