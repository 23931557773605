import { useTranslation } from '@tyro/i18n';
import { useToast } from './use-toast';

export enum FileRejectedCode {
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
}

export const useFileErrorMessage = () => {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  const showErrorMessage = (
    code: FileRejectedCode | string,
    options?: {
      size: string;
    },
  ) => {
    let errorMessage = '';

    switch (code) {
      case FileRejectedCode.FILE_TOO_LARGE: {
        errorMessage = t('common:errorMessages.fileSize', {
          size: options?.size,
        });
        break;
      }
      case FileRejectedCode.FILE_INVALID_TYPE: {
        errorMessage = t('common:errorMessages.fileInvalidType');
        break;
      }
      default: {
        errorMessage = t('common:snackbarMessages.errorUploadingFile');
      }
    }

    toast(errorMessage, { variant: 'error' });
  };

  return { showErrorMessage };
};
