import { Box, IconButton, type SvgIconProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  CheckmarkCircleIcon,
  CloseIcon,
  ErrorCircleIcon,
  InfoCircleIcon,
  WarningIcon,
} from '@tyro/icons';
import {
  SnackbarProvider as NotistackProvider,
  type SnackbarKey,
} from 'notistack';
import {
  type ForwardRefExoticComponent,
  type RefAttributes,
  useCallback,
  useRef,
} from 'react';
import StyledNotistack from './styles';

type Props = {
  children: React.ReactNode;
};

type IconType = ForwardRefExoticComponent<
  Omit<SvgIconProps, 'ref'> & RefAttributes<SVGSVGElement>
>;

export function ToastProvider({ children }: Props) {
  // biome-ignore lint/suspicious/noExplicitAny: Needs any for NotistackProvider
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const CloseButton = useCallback(
    (key: SnackbarKey) => (
      <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
        <CloseIcon />
      </IconButton>
    ),
    [onClose],
  );

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <ToastIcon Icon={InfoCircleIcon} color="info" />,
          success: <ToastIcon Icon={CheckmarkCircleIcon} color="success" />,
          warning: <ToastIcon Icon={WarningIcon} color="warning" />,
          error: <ToastIcon Icon={ErrorCircleIcon} color="error" />,
          default: null,
        }}
        action={CloseButton}
      >
        {children}
      </NotistackProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type ToastIconProps = {
  Icon: IconType;
  color: 'info' | 'success' | 'warning' | 'error';
};

function ToastIcon({ Icon, color }: ToastIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Icon sx={{ width: 24 }} />
    </Box>
  );
}
