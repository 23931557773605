import type { Editor } from '@tiptap/react';

import type { FileTransferRiuType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { InsertImageIcon } from '@tyro/icons';
import { allowedTypes, useUploadAndInsertImage } from '../../../../hooks';
import { IconButtonWithTooltip } from '../../../icon-button-with-tooltip';

interface InsertImageProps {
  editor: Editor | null;
  riuType: FileTransferRiuType;
}

export const InsertImage = ({ editor, riuType }: InsertImageProps) => {
  const { t } = useTranslation(['common']);
  const { getUploadedImagePath } = useUploadAndInsertImage({ riuType });

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const path = await getUploadedImagePath(event.target.files?.[0]);
    if (!path) return;

    if (editor) {
      editor
        .chain()
        .setImage({ src: path })
        .setTextSelection(editor.state.doc.content.size)
        .focus()
        .insertContent('<p></p>')
        .run();
    }
    event.target.value = '';
  };

  if (!editor) return null;

  return (
    <IconButtonWithTooltip
      title={t('common:tooltipTitles.insertImage')}
      placement="top"
      component="label"
    >
      <InsertImageIcon />
      <input
        type="file"
        accept={allowedTypes.join(', ')}
        hidden
        onChange={handleUploadImage}
      />
    </IconButtonWithTooltip>
  );
};
