import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { DateTimePicker, type DateTimePickerProps } from '../date-time-picker';

dayjs.extend(LocalizedFormat);

type RHFDatePickerProps<TField extends FieldValues> = {
  label?: string;
  timePickerProps?: Omit<DateTimePickerProps, 'onChange' | 'value'>;
  inputProps?: DateTimePickerProps['inputProps'];
  controlProps: UseControllerProps<TField>;
};

export const RHFTimePicker = <TField extends FieldValues>({
  label,
  controlProps,
  timePickerProps,
  inputProps,
}: RHFDatePickerProps<TField>) => {
  const {
    field: { ref, onBlur, name, value, onChange },
    fieldState: { error },
  } = useController(controlProps);

  return (
    <DateTimePicker
      {...timePickerProps}
      onChange={onChange}
      value={value ?? null}
      label={label}
      inputRef={ref}
      inputProps={{
        ...inputProps,
        onBlur,
        name,
        error: !!error,
        helperText: error?.message,
      }}
    />
  );
};
