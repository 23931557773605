import type { EditorOptions } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { useTranslation } from '@tyro/i18n';
import imageCompression from 'browser-image-compression';

import { Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  FileTransferFeature,
  type FileTransferRiuType,
  getEndpoint,
  uploadFile,
} from '@tyro/api';

import { getFileSizeLabel } from '../components';
import {
  FileRejectedCode,
  useFileErrorMessage,
} from './use-file-error-message';
import { useToast } from './use-toast';

interface UploadAndInsertImageProps {
  riuType: FileTransferRiuType;
}

export const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
const maxFileSizeInKiloBytes = 1024 * 10;
const maxFileSizeInBytes = maxFileSizeInKiloBytes * 1024;

export const useUploadAndInsertImage = ({
  riuType,
}: UploadAndInsertImageProps) => {
  const { toast, closeAllToasts } = useToast();
  const { t } = useTranslation(['common']);
  const { showErrorMessage } = useFileErrorMessage();

  const resizeAndCompressImage = async (file: File): Promise<File> => {
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1250,
      useWebWorker: true,
      fileType: file.type,
      initialQuality: 0.7,
    });
    return new File([compressedFile], file.name, { type: file.type });
  };

  const getUploadedImagePath = async (file: File | undefined) => {
    if (!file) return '';

    if (!allowedTypes.includes(file.type)) {
      return showErrorMessage(FileRejectedCode.FILE_INVALID_TYPE);
    }

    if (file.type.startsWith('image/')) {
      toast(
        <Stack direction="row" spacing={1} alignItems="center">
          <CircularProgress size={20} color="sky" />
          <span>{t('common:uploading')}</span>
        </Stack>,
        {
          variant: 'default',
        },
      );

      let processedFile: File = file;

      if (file.type !== 'image/gif') {
        processedFile = await resizeAndCompressImage(file);
      }

      if (processedFile.size > maxFileSizeInBytes) {
        return showErrorMessage(FileRejectedCode.FILE_TOO_LARGE, {
          size: getFileSizeLabel(maxFileSizeInKiloBytes),
        });
      }

      const response = await uploadFile({
        documentType: FileTransferFeature.Riu,
        additionalFormValues: {
          referenceId: riuType,
          overwrite: 'true',
        },
        files: [processedFile],
      });

      closeAllToasts();

      const data = (await response.json()) as { id: number; path: string };

      if (data.path) {
        const endpoint = new URL('', getEndpoint());
        return `${endpoint.origin}${data.path}`;
      }

      return '';
    }
  };

  const handleDropImageEditor = async (
    view: Parameters<
      NonNullable<EditorOptions['editorProps']['handleDrop']>
    >[0],
    event: Parameters<
      NonNullable<EditorOptions['editorProps']['handleDrop']>
    >[1],
    file: File,
  ) => {
    const src = await getUploadedImagePath(file);

    const coordinates = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    const imgNode = view.state.schema.nodes.image.create({ src });
    const textNode = view.state.schema.nodes.paragraph.create();

    if (coordinates) {
      const tsx = view.state.tr.insert(coordinates.pos, [imgNode, textNode]);
      const newPos = coordinates.pos + imgNode.nodeSize + 1;
      const selection = TextSelection.create(tsx.doc, newPos);
      tsx.setSelection(selection);

      view.dispatch(tsx);
      view.focus();
    }
  };

  return { getUploadedImagePath, handleDropImageEditor };
};
